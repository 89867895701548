"use strict"

const Header = {
    init: function () {
        this.body = document.querySelector('body')
        this.el = document.querySelector('.mobile-nav')
        this.list = document.querySelector('.mobile-nav__list')
        this.button = document.querySelector('.mobile-nav__button')
        this.header = document.querySelector('.header__bottom')
        this.items = document.querySelectorAll('.mobile-nav__item button')
        this.backButtons = document.querySelectorAll('.mobile-nav__back')
        this.subLists = document.querySelectorAll('.mobile-nav__sub')

        this.events()
    },

    events: function() {
        this.button.addEventListener('click', () => this.toggle())

        for (var i = 0; i < this.items.length; i++) {
            this.items[i].addEventListener('click', (e) => this.showSubList(e))
        }

        for (var i = 0; i < this.backButtons.length; i++) {
            this.backButtons[i].addEventListener('click', (e) => this.goBack(e))
        }
    },

    toggle: function () {
        if (!this.button.classList.contains('active')) {

            this.el.classList.add('active')
            this.header.classList.add('transparent')
            this.button.classList.add('active')
            this.body.classList.add('locked')
        }

        else {
            if (this.list.classList.contains('sub-active')) this.list.classList.remove('sub-active')

            this.el.classList.remove('active')
            this.header.classList.remove('transparent')
            this.button.classList.remove('active')
            this.body.classList.remove('locked')

            this.list.classList.remove('no-overflow')
        }
    },

    showSubList: function (e) {
        let target = e.currentTarget,
            subList = target.nextElementSibling,
            parentList = target.closest('.mobile-nav__list')

        parentList.classList.add('no-overflow')

        if (subList === null) return

        for (var i = 0; i < this.subLists.length; i++)
            this.subLists[i].classList.remove('sub-active')

        this.list.classList.add('sub-active')
        subList.classList.add('sub-active')
    },

    goBack: function (e) {
        for (var i = 0; i < document.querySelectorAll('.sub-active').length; i++) {
            document.querySelectorAll('.sub-active')[i].classList.remove('sub-active')
        }

        this.list.classList.remove('no-overflow')
    }
}

module.export = Header.init()