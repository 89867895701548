<template>
  <div class="map">

    <list 
      v-if="type != 'GetLocationByMainLocationStation'"
      :items="items"
      v-on:updateActive="updateActive" 
      v-on:find="findNearest" 
      :active="active" />

    <div class="map__container" :class="{'collapse': checkType(type)}">

      <infoWindow
        :isSingleLocation="checkType(type)"
        :items="items"
        :infoButtonText="infoButtonText"
        :directionsButtonText="directionsButtonText"
        :active="active" />

      <mapElem 
        :items="items" 
        :findNearest="find"
        v-on:updateActive="updateActive" 
        :active="active" />

    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import list from '../components/map-list.vue'
  import mapElem from '../components/map-el.vue'
  import infoWindow from '../components/map-info.vue'

  export default {
  name: 'map',

  data() {
  return {
  items: [],
  active: 0,
  find: false,
  type: '',
  id: document.body.dataset.id,
  directionsButtonText: '',
  infoButtonText: '',
  url: ''
  }
  },

  components: {
  'list': list,
  'mapElem': mapElem,
  'infoWindow': infoWindow
  },

  mounted() {
  console.log(this)
  console.log('El start:')
  console.log(this.$el)
  console.log('El end')
  console.log(this.$el.offsetParent)
  this.type = this.$el.parentNode.dataset.type
  this.infoButtonText = this.$el.parentNode.dataset.directions
  this.directionsButtonText = this.$el.parentNode.dataset.information
  console.log('Type: '+this.type)
  switch(this.type) {
  case 'GetLocations':
  this.url = '/umbraco/api/LocationApi/' + this.type
  break;

  case 'GetLocationsByContainer':
  this.url = '/umbraco/api/LocationApi/' + this.type + '?containerId=' + this.id
  break;

  case 'GetLocationsByItemType':
  this.url = '/umbraco/api/LocationApi/' + this.type + '?itemTypeId=' + this.id
  break;

  case 'GetLocationByMainLocationStation':
  this.url = '/umbraco/api/LocationApi/' + this.type + '?mainLocationStationId=' + this.id
  break;
  }

  this.loadItems()
  },

  methods: {
  checkType: function(type) {
  return type === 'GetLocationByMainLocationStation' ? true : false
  },

  loadItems: function() {

  axios({
  method: 'get',
  url: this.url,
  cache: false
  })

  .then((response) => this.items.push(...response.data))
  },

  updateItems: function(items) {
  this.items = items

  if(this.items[0].distance) {
  this.active = this.items[0].id
  }
  },

  updateActive: function(id) {
  this.active = id
  },

  findNearest: function() {
  this.find = true
  }
  },

  watch: {
  items: function(items) {
  this.updateItems(items)
  this.updateActive(items[0].id)
  }
  }
  }
</script>