<template>
    <div class="search__inner">
         <div v-if="showsearchheader == 'true'" class="row__header">
      <h2 class="title title--white">Søg i sorteringsguiden</h2>
    </div>
        <input type="search" ref="input" class="search__input" :placeholder="placeholder" @keyup="onKey($event.target.value)" :value="value" />

        <svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/icons/icons.svg#icon-search"></use></svg>

        <button class="button--round button--secondary search__delete" v-on:click="deleteSearch()" v-show="!loading">
            <svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/icons/icons.svg#icon-close"></use></svg>
        </button>

        <button class="button" v-on:click="smoothScroll">
            <svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/icons/icons.svg#icon-search"></use></svg>
            <span>{{ buttonText }}</span>
        </button>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'searchInput',

        data() {
          return {
            focus: false,
            value: ""
          }
        },
        
        props: [
          'placeholder',
          'buttonText',
          'classes'
        ],
        
        updated: function () {
          const self = this
          
          setTimeout(function() {
            self.focusInput()
          }, 200)
        },

        methods: {
            onKey: function(value) {
                this.$emit('updateValue', value)
                this.value = value
            },

            deleteSearch: function() {
                this.$emit('updateValue', '')
                this.value = ""
            },
            
            focusInput: function () {
              if(this.classes === undefined) return
              
              if(this.classes.indexOf('search--focus') === -1) return
              
              this.$el.querySelector('input').focus()
              
              this.$el.querySelector('input').onfocus = function (e) {
                e.preventDefault()
              }
            },
            
            elmYPosition: function (elm) {
              var y = elm.offsetTop - 200;
              var node = elm;
              while (node.offsetParent && node.offsetParent != document.body) {
                  node = node.offsetParent;
                  y += node.offsetTop;
              } return y;
            },
            
            currentYPosition: function () {
              // Firefox, Chrome, Opera, Safari
              if (self.pageYOffset) return self.pageYOffset;
              // Internet Explorer 6 - standards mode
              if (document.documentElement && document.documentElement.scrollTop)
                  return document.documentElement.scrollTop;
              // Internet Explorer 6, 7 and 8
              if (document.body.scrollTop) return document.body.scrollTop;
              return 0;
            },
            
            smoothScroll: function(e) {
                const elm = e.target.closest('.search__inner')
                var startY = this.currentYPosition();
                var stopY = this.elmYPosition(elm);
                var distance = stopY > startY ? stopY - startY : startY - stopY;
                if (distance < 100) {
                    scrollTo(0, stopY); return;
                }
                var speed = Math.round(distance / 0);
                if (speed >= 20) speed = 20;
                var step = Math.round(distance / 25);
                var leapY = stopY > startY ? startY + step : startY - step;
                var timer = 0;
                if (stopY > startY) {
                    for ( var i=startY; i<stopY; i+=step ) {
                        setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
                        leapY += step; if (leapY > stopY) leapY = stopY; timer++;
                    } return;
                }
                for ( var i=startY; i>stopY; i-=step ) {
                    setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
                    leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
                }
            }
        }
    }
</script>