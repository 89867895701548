  <template>
      <div class="map__info">
        <h4 class="title color--primary">{{ activeItem.name }}</h4>

        <div class="map__info__hours" v-if="activeItem.openingHours" v-for="openingHours in activeItem.openingHours">
          <h5>{{ openingHours.text }} <span>{{ openingHours.hours }}</span></h5>
        </div>

        <p class="map__info__disclaimer">{{ activeItem.notification }}</p>

        <a :href="activeItem.url" v-if="!isSingleLocation" class="button map__info__directions">{{ directionsButtonText }}</a>
        <a :href="'https://www.google.dk/maps?q=' + activeItem.name + ', ' + activeItem.address" target="_blank" class="button button--secondary">{{ infoButtonText }}</a>
      </div>
  </template>

  <script>
    export default {
      name: 'infoWindow',

      data() {
        return {
          activeItem: 1248
        }
      },

      props: [
        'active',
        'items',
        'isSingleLocation',
        'infoButtonText',
        'directionsButtonText'
      ],
      
      mounted: function () {
        console.log(this.infoButtonText)
      },

      methods: {
          setActive: function(id) {
          
            for(let i = 0; i < this.items.length; i++) {
              if(this.items[i].hasOwnProperty('id') && this.items[i].id === id) {
                this.activeItem = this.items[i]

                return
              }
            }
          }
      },

      watch: {
        active: function(id) {
          this.setActive(this.active)
        }
      }
    }
  </script>