import approve from 'approvejs'

export default {
    getFormByName(name) {
        return window.ecreoForms.filter(form => form.name === name)[0]
    },

    getFormById(id) {
        id = id.replace(/-/g, '')
        return window.ecreoForms.filter(form => form.id === id)
    },

    getForms() {
        return window.ecreoForms
    }
}

function EcreoForm(options) {
    const ecreoForm = {
        pages: [],
        fields: [],

        _initForm() {
            this.fieldsets = this.form.querySelectorAll('.ecreo-form__fieldset')

            const previousButton = this.form.querySelector('.ecreo-form__action--previous')
            const fields = this.form.querySelectorAll('.ecreo-form__question')
            this.submitButton = this.form.querySelector('button[type="submit"]')

            for (let i = 0; i < fields.length; i++) {
                this.fields.push(this._createFieldObject(fields[i]))
            }

            if (previousButton) {
                previousButton.addEventListener('click', this._handleBack.bind(this))
            }

            this.form.onsubmit = this._handleSubmit.bind(this)

            this._watchFields()
        },

        _createFieldObject(container) {
            const fields = container.querySelectorAll('.ecreo-form__question__input')

            return {
                inlineValidating: false,
                rules: this._getRules(fields[0]),
                container: container,
                errorElement: container.querySelector('.ecreo-form__question__error-message'),
                getValue() {
                    if (fields.length > 1) {
                        for (let i = 0; i < fields.length; i++) {
                            if (fields[i].checked) {
                                return fields[i].value
                            }
                        }
                    } else {
                        return fields[0].value
                    }
                },
                addEventListener(type, handler) {
                    for (let i = 0; i < fields.length; i++) {
                        fields[i].addEventListener(type, handler)
                    }
                }
            }
        },

        _getRules(element) {
            const rules = {}

            if (element.hasAttribute('data-val') && element.getAttribute('data-val') != false) {
                if (element.hasAttribute('data-val-required')) {
                    rules.required = {
                        required: true,
                        message: element.getAttribute('data-val-required')
                    }
                }

                if (element.hasAttribute('data-regex')) {
                    rules.format = {
                        regex: new RegExp(element.getAttribute('data-regex')),
                        message: element.getAttribute('data-val-regex')
                    }
                }
            }

            return rules
        },

        _watchFields() {
            for (let i = 0; i < this.fields.length; i++) {
                this.fields[i].addEventListener('change', () => this._validateField(this.fields[i]))
            }
        },

        _validateField(field) {
            const validation = approve.value(field.getValue(), field.rules)

            if (!validation.approved) {
                field.errorElement.textContent = validation.errors[0]

                if (!field.inlineValidating) {
                    field.inlineValidating = true
                    field.addEventListener('input', () => this._validateField(field))
                }
            } else {
                if (field.errorElement != null) {
                    field.errorElement.textContent = ''
                }
            }

            return validation.approved
        },

        _handleBack(event) {
            event.preventDefault()

            const previousPage = this.pages.pop()

            this.form.parentNode.replaceChild(previousPage, this.form)

            this.form = previousPage

            this.isLastPage = false

            this._initForm()
        },

        _handleSubmit(event) {
            let valid = true

            event.preventDefault()

            for (let i = 0; i < this.fields.length; i++) {
                if (!this._validateField(this.fields[i])) {
                    valid = false
                }
            }

            if (valid) {
                const formData = new FormData(this.form)
                const req = new XMLHttpRequest()

                this.beforeSubmit(this.form)

                // Disable the submit button
                this.submitButton.disabled = true

                req.addEventListener('load', () => this._handleSuccess(req))
                req.addEventListener('error', () => this._handleError())
                req.addEventListener('abort', () => this._handleError())

                req.open(this.form.getAttribute('method'), this.form.getAttribute('action'))
                req.send(formData)
            } else {
                this.onInvalid()
            }

            return false
        },

        _handleSuccess(req) {
            // Enable the submit button
            this.submitButton.disabled = false

            if (this.isMultiPage && !this.isLastPage) {
                const parser = new DOMParser()
                const response = parser.parseFromString(req.response, 'text/html')
                const form = response.getElementById(`ecreo-form-${ecreoForm.id}`).querySelector('form')
                let formData = response.querySelector('#form-data')

                if (formData) {
                    formData = JSON.parse(formData.textContent)

                    this.isLastPage = formData.isLastPage
                    this.recordValues = formData.recordValues
                }

                this.form.parentNode.replaceChild(form, this.form)

                this.pages.push(this.form)

                this.form = form

                this._initForm()
            } else {
                if (req.responseURL === window.location.href || req.responseURL === undefined) {
                    if (this.submitted) {
                        const parser = new DOMParser()
                        const response = parser.parseFromString(req.response, 'text/html')
                        const message = response.querySelector('.ecreo-form__submit-message').textContent.trim()

                        this.submitted(message)
                    } else {
                        this._showSuccessMessage()
                    }
                } else {
                    window.location = req.responseURL
                }
            }
        },

        _handleError() {
            // Enable the submit button
            this.submitButton.disabled = false
            this.onError()
        },

        _showSuccessMessage() {
            const inner = document.querySelector(`#ecreo-form-${this.id} .ecreo-form__inner`)
            const element = document.querySelector(`#ecreo-form-${this.id} .ecreo-form__success-message`)

            inner.classList.add('ecreo-form__inner--submitted')
            element.classList.add('ecreo-form__success-message--visible')
        },

        onInvalid() { return false },
        onError() { alert('Something went wrong when submitting the form') },
        beforeSubmit(form) { return false }
    }

    ecreoForm.name = options.name
    ecreoForm.id = options.id
    ecreoForm.isMultiPage = options.isMultiPage
    ecreoForm.isLastPage = options.isLastPage
    ecreoForm.fieldSetConditions = options.fieldSetConditions
    ecreoForm.fieldConditions = options.fieldConditions
    ecreoForm.recordValues = options.recordValues
    ecreoForm.form = document.getElementById(`ecreo-form-${ecreoForm.id}`).querySelector('form')

    ecreoForm._initForm()

    return ecreoForm
}

window.ecreoForms = window.ecreoForms || []
window.EcreoForm = EcreoForm
