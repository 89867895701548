"use strict";

const Banner = {
    init: function () {
        this.el = document.querySelector('.banner')
        this.containers = document.querySelectorAll('.container')

        // Check if element exists
        if (this.el == null) return

        this.value = this.el.getAttribute('data-text')

        this.close = this.el.querySelector('.banner__close')
        this.onClick()
    },

    onClick: function () {
        const self = this

        this.close.addEventListener('click', function () {
            self.el.remove()

            for (var i = 0; i < self.containers.length; i++) {
                if(self.containers[i].classList.contains('has-notification')) self.containers[i].classList.remove('has-notification')
            }

            self.setCookie(self.value)
        })
    },

    //getCookie: function(cookie) {
    //    const name = cookie + "=";
    //    const ca = document.cookie.split(';');

    //    for (var i = 0; i < ca.length; i++) {
    //        var c = ca[i];
    //        while (c.charAt(0) == ' ') c = c.substring(1);
    //        if (c.indexOf(name) == 0) {
    //            return c.substring(name.length, c.length);
    //        }
    //    }

    //    return "";
    //},
 
    //checkCookie: function () {
    //    var notification = this.getCookie("notification")

    //    if (notification != "") {
    //        if (notification == this.value) {
    //            this.el.remove()
    //        }
    //    }
    //},

    setCookie: function (value) {
        const date = new Date();
        date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));

        const expires = "expires=" + date.toUTCString();

        document.cookie = 'notification-' + window.location.pathname + '=' + value + ';' + expires + ';path=/';
    }
}

module.exports = Banner.init();