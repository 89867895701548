"use strict"

const Header = {
    init: function () {
        this.el = document.querySelector('.header')
        this.top = this.el.querySelector('.header__top')
        this.dropdown = this.el.querySelectorAll('.header__dropdown')
        this.topHeight = this.top.offsetHeight
        this.buttons = this.el.querySelectorAll('.header__nav__item')

        this.events()
    },

    events: function () {
        window.addEventListener('scroll', () => this.onScroll()) 

        document.body.addEventListener('click', (e) => this.toggleDropdown(e))

        for (var i = 0; i < this.dropdown.length; i++)
        {
            this.dropdown[i].addEventListener('click', (e) => e.stopPropagation())
        }
    },

    onScroll: function (e) {
        let scrolledFromTop = window.pageYOffset,
            windowWidth = window.innerWidth

        if (scrolledFromTop > this.topHeight && windowWidth >= 768) {
            this.el.classList.add('header--fixed')
            document.body.style.paddingTop = this.el.offsetHeight + 'px'
        }

        else {
            this.el.classList.remove('header--fixed')
            document.body.style.paddingTop = 0
        }
    },

    toggleDropdown: function (e) {
        let target = e.target

        if (target.classList.contains('header__nav__item')) {
            e.preventDefault()

            if (target.nextElementSibling != null) {
                if (!target.classList.contains('active')) {
                    for (var i = 0; i < this.dropdown.length; i++) {
                        this.buttons[i].classList.remove('active')
                    }

                    target.classList.add('active')
                }

                else
                    target.classList.remove('active')
            }

            for (var i = 0; i < this.dropdown.length; i++) {
                this.buttons[i].classList.remove('active--no-dropdown')
            }
        }

        else {
            for (var i = 0; i < this.dropdown.length; i++) {
                this.buttons[i].classList.remove('active')
            }

            if (document.querySelector('.root-link') != null) document.querySelector('.root-link').classList.add('active--no-dropdown')
        }
    }
}

module.export = Header.init()