<template>
    <div class="search__results">
        <ul class="search__list" v-if="sortItems != null && sortItems.length > 0">
            <li class="search__category">
                <span>Sorteringsguide</span>
                <span>Container på genbrugsstation</span>
            </li>

            <li class="search__item" v-for="(item, index) in sortItems" :key="index">
                <a :href="item.url">
                    <div class="search__item__title">
                        <h4 class="title title--sm">{{ item.name }}</h4>
                        <div class="search__item__subtitle">
                            <span class="search__item__location" v-for="(location, index) in item.locations" :key="index">{{ location }}</span>
                        </div>
                    </div>

                    <div class="search__item__placement" v-if="item.hasContainer">
                        <img v-if="item.pictogramUrl" v-bind:src="item.pictogramUrl" style="width: 80px; height: 80px; margin-right: 25px" />
                        <h5>{{ item.placementName }}</h5>
                    </div>
                </a>
            </li>
        </ul>
        <ul class="search__list" v-if="textItems != null && textItems.length > 0">
            <li class="search__category">
                <span>Sorteret efter relevans</span>
            </li>

            <li class="search__item" v-for="(item, index) in textItems" :key="index">
                <a :href="item.url">
                    <div class="search__item__title">
                        <h4 class="title title--sm">{{ item.name }}</h4>
                        <div class="search__item__subtitle">
                            <img :src="item.categoryIcon" v-if="item.categoryIcon != ''" alt="" />
                            <span :style="{ color: '#'+item.categoryColor }">{{ item.categoryName }}</span>
                        </div>
                    </div>
                </a>
            </li>
        </ul>

        <ul class="search__list" 
            v-if="
                textItems != null && 
                textItems.length === 0 && 
                sortItems != null && 
                sortItems.length === 0">

            <li class="search__category">
                <h3>{{ noResults }} "{{ searchValue }}"</h3>
            </li>

        </ul>
    </div>
</template>

<script>
  export default {
    name: 'searchList',

    props: [
      'sortItems',
      'textItems',
      'searchValue',
      'noResults'
    ]
  }
</script>