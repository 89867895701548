"use strict"

const Faq = {
    init: function () {
        this.el = document.querySelector('.faq')

        if(this.el === null) return

        this.buttons = this.el.querySelectorAll('.faq__nav button')
        this.articles = this.el.querySelectorAll('.faq__item')
        this.mobileButtons = this.el.querySelectorAll('.faq__item__button')

        this.events()
    },

    events: function () {
        for (var i = 0; i < this.buttons.length; i++)
            this.buttons[i].addEventListener('click', (e) => this.onClick(e))

        for (var i = 0; i < this.mobileButtons.length; i++)
            this.mobileButtons[i].addEventListener('click', (e) => this.toggleAccordion(e))
    },

    onClick: function (e) {
        let target = e.target,
            id = target.dataset.id

        for (let i = 0; i < this.buttons.length; i++) {
            this.buttons[i].classList.remove('active')
        }

        if (!target.classList.contains('active')) {
            target.classList.add('active')
        } else {
            target.classList.remove('active')
        }

        for (let i = 0; i < this.articles.length; i++) {
            if (this.articles[i].dataset.id === id) {
                this.articles[i].classList.add('active')
            } else {
                this.articles[i].classList.remove('active')
            }
        }
    },

    toggleAccordion: function (e) {
        let target = e.target,
            id = target.dataset.id

        this.articles.forEach(function (el) {
            if (el.dataset.id === id) {
                if (!el.classList.contains('mobile-active')) {
                    el.classList.add('active')
                    el.classList.add('mobile-active')
                }
                else {
                    el.classList.remove('active')
                    el.classList.remove('mobile-active')
                }
            }
        })
    }
}

module.export = Faq.init()