import Vue from 'vue/dist/vue'
import objectFitImages from 'object-fit-images';
import svg4everybody from 'svg4everybody';

Vue.config.devtools = false
Vue.config.productionTip = false

objectFitImages()
svg4everybody()

// Components
import Banner from './components/banner'
import SmoothScroll from './components/smooth-scroll'

// Modules
import Header from './modules/header'
import Faq from './modules/faq'
import MobileNav from './modules/mobile-nav'
import EcreoForms from './modules/ecreo-forms'
import Employees from './modules/employees'
import FaqAccordion from './modules/faq-accordion'

// Initialize Vue components

import Map from './modules/map.vue'
import Search from './modules/search.vue'

if (document.querySelector('.map')) {
	new Vue({
		el: '.map',
		render: (h) => h(Map)
	})
}

if (document.querySelectorAll('.init-search').length > 0) {
	var elems = document.querySelectorAll('.init-search')

	for(var i = 0; i < elems.length; i++) {
		new Vue({
			el: elems[i],
			render: (h) => h(Search)
		})
	}
}