  <template>
    <ul class="map__list">
      <button class="button button--secondary" v-on:click="find()">Find nærmeste genbrugsstation</button>

      <li class="map__item" 
        v-for="(value, key) in items" 
        v-bind:class="{active: value.id === active, show: isDropdown}"
        v-on:click="onClick(value.id)">

        <div class="map__item__title">
            <h3 class="title title--sm">{{ value.name }}</h3>
            <span>{{ value.address }}</span>
        </div>

        <span class="map__item__distance">{{ value.distance }}</span>
      </li>
    </ul>
  </template>

  <script>
    export default {
      name: 'list',

      data() {
        return {
          isDropdown: false
        }
      },

      props: [
        'items',
        'active'
      ],

      methods: {
        updateDistance: function() {
          var a = this.items[0]

          if(!a) {
            setTimeout(() => this.updateDistance(), 100)
          }
          else {
            if(!a.distanceValue) {
                setTimeout(() => this.updateDistance(), 100)
            }
            else {
              this.items.sort(function(a, b) {
                return a['distanceValue'] < b['distanceValue'] ? -1 : 1
              })
            }
          }
        },

        onClick: function(id) {
            if(document.body.offsetWidth > 768)
              this.$emit('updateActive', id)
            
            else {
              if(this.isDropdown) {
                this.$emit('updateActive', id)

                this.isDropdown = false
              }
              else {
                this.isDropdown = true
              }
            }
        },

        find: function() {
          this.updateDistance()

          this.$emit('find')
        }
       }
    }
  </script>