"use strict"

const employees = {
    init: function () {
        this.el = document.querySelector('.employees')

        if(this.el === null) return

        this.items = this.el.querySelectorAll('.employee')

        this.scrollToEmployee()
    },

    scrollToEmployee: function () {
        if (window.location.hash) {
            const target = document.querySelector(window.location.hash)
            const offset = target.getBoundingClientRect().top - 120

            window.scrollTo(0, offset)
        }
        window.scrollTo(0, 120)
    }
}

module.exports = employees.init();