"use strict"

const Faq = {
    init: function () {
        this.el = document.querySelector('.faq-accordion')

        if(this.el === null) return

        this.buttons = this.el.querySelectorAll('[data-faq-button]')
        this.currentElm = null;
        this.lastElm = null;

        this.events()
    },

    events: function () {
        for (var i = 0; i < this.buttons.length; i++)
            this.buttons[i].addEventListener('click', (e) => this.onClick(e))
    },

    closeAccordions: function () {
        let activeTitles = this.el.querySelectorAll('.faq__element-title.active')
        let activeContent = this.el.querySelectorAll('.faq__element-content.active')

        if (activeTitles.length > 0 && activeContent.length > 0) {
            for (var i = 0; i < activeTitles.length; i++)
                activeTitles[i].classList.remove("active")

            for (var i = 0; i < activeContent.length; i++)
                activeContent[i].classList.remove("active")
        }
    },

    onClick: function (e) {
        let self = e.target.closest("[data-faq-button]");
        this.currentElm = self;
        this.closeAccordions()
        if (self.classList.contains("faq__category-title") && self.classList.contains("active")) {
            self.classList.remove("active")
            self.nextElementSibling.classList.remove("active")
            this.lastElm = null;
        }
        else if (this.currentElm != this.lastElm) {
            self.classList.add("active")
            self.nextElementSibling.classList.add("active")
            this.lastElm = self;
        }
        else {
            this.lastElm = null;
        }
    },
}

module.export = Faq.init()