<template>
  <div class="map__elem"></div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'mapElem',

    data() {
      return {
        'key': 'AIzaSyAys4Bb32CV8Zg2mYPHQ-TVCFiGgGQiDGU',
        'center': {
            lat: 55.39583,
            lng: 10.38861
        },
        'mapStyles': [{ "featureType": "administrative", "elementType": "geometry", "stylers": [{ "visibility": "off" }] }, { "featureType": "administrative.land_parcel", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "landscape.man_made", "elementType": "geometry.fill", "stylers": [{ "visibility": "simplified" }] }, { "featureType": "poi", "stylers": [{ "visibility": "off" }] }, { "featureType": "poi", "elementType": "labels.text", "stylers": [{ "visibility": "off" }] }, { "featureType": "road", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "road.local", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "stylers": [{ "visibility": "off" }] }],
        'markers': [],
        'activeMarker': 0,
        'initiated': false
      }
    },

    props: [
      'items',
      'active',
      'findNearest'
    ],

    methods: {
      init: function() {
        // Insert script in body
        const script = document.createElement('script')
        script.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=' + this.key + '&callback=initMap&language=da-dk')

  document.body.appendChild(script)

  window.initMap = () => this.loadMap()
  },

  loadMap: function() {
  this.map = new google.maps.Map(this.$el, {
  center: this.center,
  zoom: 11,
  disableDefaultUI: true,
  styles: this.mapStyles
  });

  this.setMarkers()
  },

  setMarkers: function() {
  const self = this

  // Place marker for each item in marker-object

  for (let i = 0; i < this.items.length; i++) {

          if(this.items[i].latitude != "" || this.items[i].longitude != "") {
            let lat = parseFloat(this.items[i].latitude),
                lng = parseFloat(this.items[i].longitude)

            let marker = new google.maps.Marker({
              map: this.map,
              position: {lat: lat, lng: lng},
              animation : google.maps.Animation.DROP,
              icon: {
                url: '/gfx/img-pin.png',
                scaledSize: new google.maps.Size(48, 64)
              },
              markerKey: i,
              itemId: self.items[i].id
            });

            marker.addListener('click', function() {
              self.$emit('updateActive', marker.itemId)
            })

            this.markers.push(marker)
            
            this.items[i]['markerKey'] = i 

            
            this.setActive(this.items[0].id)
          }

        }
      },

      getUserLocation: function() {
        const self = this

        navigator.geolocation.getCurrentPosition(function (position) {
            const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            }

            self.getUserDistance(pos)
            self.map.setCenter(pos)
        })
      },

      getUserDistance: function(pos) {
        const origins      = [pos],
              destinations = [],
              self = this

        for(let i = 0; i < this.items.length; i++) {
          if(this.items[i].latitude != "" || this.items[i].longitude != "")
            destinations.push(this.items[i].latitude + ',' + this.items[i].longitude)
        }

        const distanceMatrix  = new google.maps.DistanceMatrixService()

        const distanceRequest = { 
                origins: origins, 
                destinations: destinations, 
                unitSystem: google.maps.UnitSystem.METRIC, 
                travelMode: google.maps.TravelMode.WALKING,
                avoidHighways: false,
                avoidTolls: false
              }

        distanceMatrix.getDistanceMatrix(distanceRequest, function(response, status) {

          if (status != google.maps.DistanceMatrixStatus.OK) {
            alert('Error was: ' + status);
          }
          else {
            const elements = response.rows[0]['elements']

            for(let i = 0; i < elements.length; i++) {
              self.items[i]['distance'] = elements[i].distance.text
              self.items[i]['distanceValue'] = elements[i].distance.value
            }
          }

        })
      },

      setActive: function(id) {
        this.markers.forEach((el) => el.setIcon({url: '/gfx/img-pin.png', scaledSize: new google.maps.Size(48, 64)}))

        this.markers.sort(function(a, b) {
          return a['markerKey'] < b['markerKey'] ? -1 : 1
        })

        for(let i = 0; i < this.items.length; i++) {

          if(this.items[i].hasOwnProperty('id') && this.items[i]['id'] === id) {
            let activeKey = this.items[i]['markerKey']


            if(activeKey != undefined) {            
              this.markers[activeKey].setIcon({url: '/gfx/img-pin-active.png', scaledSize: new google.maps.Size(48, 64)})

              let markerLat = this.markers[activeKey].getPosition().lat()
              let markerLng = this.markers[activeKey].getPosition().lng()

              this.map.setCenter({lat: markerLat, lng: markerLng})

            }

          }

        }
      }
    },

    watch: {
      active: function(id) {
        this.setActive(id)
      },

      items: function() {
        if(!this.initiated) {
            this.init()

            this.initiated = true
        }
      },

      findNearest: function() {
        if(navigator.geolocation)
          this.getUserLocation()
      }
    }
  }
</script>