<template>
  <div 
    class="search"
    :class="classes">
      <div v-if="showsearchheader == 'true'" class="container">
        <div class="row__header">
          <h2 class="title">Søg i sorteringsguiden</h2>
      </div>
    </div>
    
    <div class="container">

      <searchInput
        :loading="fetching"
        v-on:updateValue="updateValue"
        :placeholder="placeholder"
        :buttonText="buttonText" 
        :classes="classes"
        :showsearchheader="showsearchheader"
         />

      <searchList 
        :sortItems="sortItems"
        :textItems="textItems"
        :searchValue="searchValue"
        :noResults="noResults"
        :ready="ready"
      />

    </div>
  </div>
</template>

<script>
  import { debounce } from 'lodash'
  import axios from 'axios'
  import searchList from '../components/search-list.vue'
  import searchInput from '../components/search-input.vue'

  export default {
    name: 'search',

    data() {
      return {
        node: document.body.getAttribute('data-id'),
        classes: this.$root.$options.el.dataset.classes,
        searchType: this.$root.$options.el.dataset.search,
        limit: this.$root.$options.el.dataset.limit,
        sortItems: [],
        texItems: [],
        searchValue: '',
        placeholder: this.$root.$options.el.dataset.placeholder,
        buttonText: this.$root.$options.el.dataset.buttontext,
        noResults: this.$root.$options.el.dataset.errortext,
        showsearchheader: this.$root.$options.el.dataset.showsearchheader
      }
    },
    
    components: {
      'searchList': searchList,
      'searchInput': searchInput
    },

    methods: {
      updateValue: function(value) {
        this.searchValue = value

        if (this.searchValue.length >= 2) {

          if (!this.$el.classList.contains('active')) {
            this.$el.classList.add('active')
            this.$el.querySelector('.search__delete').classList.add('show')
          }        

          this.doSearch()
        } else {
          if (this.$el.classList.contains('active')) {
            this.$el.classList.remove('active')
            this.$el.querySelector('.search__delete').classList.remove('show')
          }
        }
      },

      doSearch: debounce(function() {
        axios.get(`/umbraco/api/Search/${this.searchType}?search=${this.searchValue}&nodeId=${this.node}&limit=${this.limit}`)
          .then((response) => {
            let data = response.data

            this.sortItems = data.sortGuideResults
            this.textItems = data.textSearchResults
          })
          .catch((error) => {
            console.log(error);
          })
      }, 200)
    }
  }
</script>